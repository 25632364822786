.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    animation: gradient 15s ease infinite;
    z-index: 9999;
}

.blog_single{
    padding: 20px;
}

.blog-description{
    min-height: 100px;
    max-height: 120px;
    overflow: hidden;
  }

  .len_text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.blog_post_item .blog_content:hover {
    cursor: pointer;
}

.blog_container > img {
    max-width: inherit;
}