.blog-description{
    min-height: 100px;
    max-height: 120px;
    overflow: hidden;
  }

  .len_text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}