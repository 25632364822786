.app-container {
    position: relative;
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    animation: gradient 15s ease infinite;
    z-index: 9999;
}